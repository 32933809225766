<template>
  <div class="flightbooking_form_rightbody">
    <div class="heading">
      <h3>
        <div class="d-flex align-items-center">
          <span>
            <img src="/assets/sabre-img/chair.png" />
          </span>
          <strong>{{$t("sabre.booking.seat-box.seat-selection")}}</strong>
        </div> לורם איפסום דולור קומיט
      </h3>
    </div>
    <div class="flightbooking_form_middel">
      <seat-one-form :passengerIndex="inx" v-for="(person, inx) in passengers" :key="inx"
        :data="person"
        @sendSegmentData="showChooseSeatModal"/>
    </div>
    <div class="flightbooking_form_footer">
      <p>{{$t("sabre.booking.seat-box.overall-supplement")}} <strong> {{ priceString }} </strong>
      </p>
    </div>

    <b-modal
      id="seat-selection-table"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="modal-dialog-centered"
      modal-class="selectdmodal"
      classList=""
      :no-close-on-backdrop="true"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="() => close()"></button>
      </template>
      <ticket-selection-modal :segmentData="selectedSegmentData" @update="dataUpdate"/>
    </b-modal>

  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';

export default {
  name: 'seat-selection-form',
  mixins: [gMixin],
  components: {
    BModal,
    SeatOneForm: () => import('./seatOneForm'),
    TicketSelectionModal: () => import('./ticketSelectionModal'),
  },
  computed: {
    ...mapGetters({
      passengers: 'GET_SABRE_PASSENGER_DATA',
      selectedSeatData: 'GET_SABRE_FO_BOOKING_SELECTED_SEAT_DATA',
    }),
    totalPrice() {
      const { selectedSeatData } = this;
      return selectedSeatData?.reduce((total, current) => total + (current?.price || 0), 0) || 0;
    },
    priceString() {
      const { totalPrice, selectedSeatData } = this;
      return totalPrice ? `${this.convertCurrencySymbol(selectedSeatData[0]?.currencyCode || '')}${totalPrice}` : 0;
    },
  },
  data: () => ({
    selectedSegmentData: null,
  }),
  methods: {
    showChooseSeatModal(data) {
      this.selectedSegmentData = data;
      this.$store.dispatch('FETCH_SABRE_FO_SEATS_ORIGINAL_DATA', data);
    },
    dataUpdate(isHide) {
      if (isHide) this.$bvModal.hide('seat-selection-table');
      this.$emit('update', { type: 'seat', data: this.selectedSeatData });
    },
  },
};
</script>
